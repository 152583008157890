import { css, cx } from '@emotion/css';
import {
  AppHeaderBar as DsAppHeaderBar,
  AppHeaderBarItemWithContainer,
  AppHeaderBarNavItem,
  CustomUiText,
  HeadingText,
  Icon,
  ImmutableLogoFullWordmarkAndSymbolLight,
  InlineEllipsizedTextLine,
  StandardButton,
  StyledLink,
} from '@imtbl/design-system';
import { BalanceControl } from 'components/BalanceControl';
import { NextLink, StyledNextLink } from 'components/DsConnectorButtons';
import {
  BALANCE_CONTROL,
  COLLECTION_PAGE,
  HOME_PAGE,
  INVENTORY_PAGE,
} from 'config/pages';
import { useImxLink } from 'context/ImxLink';
import { useFeatureFlag } from 'hooks/useFeatureFlag.hook';
import {
  ButtonEventName,
  createButtonEvent,
  createFlowEvent,
  FlowEventName,
  sendAnalytics,
} from 'libs/analytics';
import { useRouter } from 'next/router';
import ROUTES from 'utils/router';

import { FEATURE_FLAG } from '../../config/feature-flags';
import { FeatureFlag } from '../FeatureFlag';
import { WalletDropdownMenu } from '../WalletDropdownMenu';
import * as styles from './AppHeaderBar.styles';

export function AppHeaderBar() {
  const {
    linkState: { walletAddress, request },
    link,
  } = useImxLink();
  const walletLoading = request.setup.inProgress;
  const { route } = useRouter();
  const showRevampedDepositFlow = useFeatureFlag(
    FEATURE_FLAG.ENABLE_REVAMPED_DEPOSIT,
  );

  const useAlternateHeaderStyling = useFeatureFlag(
    FEATURE_FLAG.ENABLE_NEW_HOMEPAGE,
  );

  // NOTE: Route contains /inventory*' or '/inventory/assets*'.
  const isInventorySelected = route.match(INVENTORY_PAGE.matcher) != null;

  // NOTE: Route contains '/assets*'.
  const isMarketplaceSelected =
    !isInventorySelected && route.match(HOME_PAGE.matcher) != null;

  const handleConnectWalletClick = () => {
    sendAnalytics(
      createButtonEvent(ButtonEventName.globalNavConnectWalletPressed),
      createFlowEvent(FlowEventName.connectWalletStarted),
    );
    link?.setup()?.catch(console.error);
  };

  const handleBalancesButtonClick = () => {
    // @NOTE: this could result in duplicate click events if users press button to close balances dropdown.
    sendAnalytics(
      createButtonEvent(ButtonEventName.globalNavBalancesBtnPressed),
    );
  };

  return (
    <DsAppHeaderBar
      testId="appHeader"
      logoArea={
        <NextLink href={ROUTES.homePath()} title="Click here to goto homepage">
          <ImmutableLogoFullWordmarkAndSymbolLight
            className={css`
              min-height: 36px;
            `}
          />
        </NextLink>
      }
      localNavItems={
        !useAlternateHeaderStyling ? (
          <AppHeaderBarNavItem selected={isMarketplaceSelected}>
            <StyledNextLink
              href={ROUTES.homePath()}
              testId="localNavBtn--marketplace"
            >
              <Icon
                ligature="action_search"
                className={cx(
                  styles.TopLevelNavIcon___isMobile,
                  styles.TopLevelNavIcon___isDesktop,
                )}
              />
              {COLLECTION_PAGE.label}
            </StyledNextLink>
          </AppHeaderBarNavItem>
        ) : (
          <></>
        )
      }
      globalNavItems={
        <>
          {walletAddress && (
            <>
              <AppHeaderBarNavItem selected={isInventorySelected}>
                <StyledNextLink
                  href={ROUTES.inventoryAssetsPath()}
                  testId="globalNavBtn--inventory"
                >
                  <Icon
                    ligature="misc_inventory"
                    className={cx(
                      styles.TopLevelNavIcon___isMobile,
                      styles.TopLevelNavIcon___isDesktop,
                    )}
                  />
                  {INVENTORY_PAGE.label}
                </StyledNextLink>
              </AppHeaderBarNavItem>

              {!showRevampedDepositFlow && (
                <AppHeaderBarNavItem testId="globalNavBtn--buyCrypto">
                  <StyledLink onClick={link.fiatToCrypto} asButton>
                    <Icon
                      ligature="misc_credit_card"
                      className={cx(
                        styles.TopLevelNavIcon___isMobile,
                        styles.TopLevelNavIcon___isDesktop,
                      )}
                    />
                    Buy Crypto
                  </StyledLink>
                </AppHeaderBarNavItem>
              )}

              <FeatureFlag name={FEATURE_FLAG.ENABLE_SELL_CRYPTO}>
                <AppHeaderBarNavItem testId="globalNavBtn--sellCrypto">
                  <StyledLink onClick={link.cryptoToFiat} asButton>
                    <Icon
                      ligature="misc_credit_card"
                      className={cx(
                        styles.TopLevelNavIcon___isMobile,
                        styles.TopLevelNavIcon___isDesktop,
                      )}
                    />
                    Sell Crypto
                  </StyledLink>
                </AppHeaderBarNavItem>
              </FeatureFlag>

              <AppHeaderBarItemWithContainer
                onClick={handleBalancesButtonClick}
                title={<CustomUiText>{BALANCE_CONTROL.title}</CustomUiText>}
                titleIcon={
                  <Icon ligature="currency_dollar__circle" iconSize="29px" />
                }
                itemContainerHeading={
                  <CustomUiText fontSize="large" fontWeight="bold">
                    {BALANCE_CONTROL.containerTitle}
                  </CustomUiText>
                }
                testId="globalNavBtn--balances"
              >
                <BalanceControl testId="balanceControl" />
              </AppHeaderBarItemWithContainer>
            </>
          )}
        </>
      }
      walletFunctionalityClassName={cx({
        [styles.WalletFunctionalityContainer]: !walletAddress,
        [styles.WalletFunctionalityContainer___isDesktop]: !walletAddress,
      })}
      walletFunctionality={
        walletAddress ? (
          <AppHeaderBarItemWithContainer
            title={
              <InlineEllipsizedTextLine
                TextComponentTag={CustomUiText}
                text={walletAddress}
                leftSideLength={6}
              />
            }
            statusIcon={
              <div className={styles.WalletFunctionalityContainer_StatusIcon} />
            }
            className={
              styles.WalletFunctionalityContainer_DropdownButton___isDesktop
            }
            itemContainerHeading={
              <InlineEllipsizedTextLine
                TextComponentTag={HeadingText}
                text={walletAddress}
                leftSideLength={6}
              />
            }
            testId="walletFunctionalityBtn--dropdown"
          >
            <WalletDropdownMenu />
          </AppHeaderBarItemWithContainer>
        ) : (
          <AppHeaderBarNavItem
            className={cx(
              styles.WalletFunctionalityContainer_ConnectButton___isDesktop,
            )}
            disabled={walletLoading}
            testId="walletFunctionalityBtn--connect"
          >
            <StandardButton
              onClick={handleConnectWalletClick}
              buttonKind="ultimate-cta"
              testId="walletFunctionalityBtn--connectWallet"
            >
              Connect Wallet
            </StandardButton>
          </AppHeaderBarNavItem>
        )
      }
      className={
        useAlternateHeaderStyling
          ? styles.AppHeaderBar___isAlternateStyling
          : ''
      }
    />
  );
}
